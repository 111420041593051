<!--
 * @Author: Jin Biao
 * @Date: 2022-02-15 15:12:18
 * @LastEditTime: 2022-06-12 22:43:21
 * @Description:  
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar';
export default {
  components: {
    SideBar,
  },

  data() {
    return {
      groups: [
        {
          label: '个性装修',
          list: [
            {
              name: '系统导航',
              path: '/mall/store/navigation-tpl',
            },

            // {
            //   name: '开屏页',
            //   path: '/mall/store/open-screen',
            // },
          ],
        },
      ],
    };
  },
};
</script>
